/* React Spring Bottom Sheet */
:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: #fff;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
}

#root {
  /* equivalent to 100dvh in major browsers */
  min-height: calc(100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0));
}

@supports (-webkit-touch-callout: none) {
  #root {
    /* for ios safari 15, safe-area-inset-bottom is 0, so a special fix apply here */
    min-height: -webkit-fill-available;
  }
}

.fullHeight {
  /* equivalent to 100dvh in major browsers */
  height: calc(100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0));
}

@supports (-webkit-touch-callout: none) {
  .fullHeight {
    /* for ios safari 15, safe-area-inset-bottom is 0, so a special fix apply here */
    height: -webkit-fill-available;
  }
}

.notistack-SnackbarContainer {
  margin-bottom: var(--navBarHeight) !important;
}

/* [data-rsbs-content],
[data-rsbs-scroll] {
  overflow: visible;
} */
[data-rsbs-header] {
  box-shadow: none;
}

[data-rsbs-overlay] {
  max-width: 600px;
  margin: 0 auto;

}

[data-rsbs-content] {
  padding: 0 0 calc(env(safe-area-inset-bottom) + 1rem) 0;
}

.dark-mode [data-rsbs-overlay] {
  background: #121212;
}