h1#LocationCode {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 10px;
  text-align: center;
  font-weight: bold;
}

#map-holder {
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  overflow: hidden;
  background-size: 30px;
  background-repeat: repeat;
}

#map-holder.light-mode {
  background-image: url("lightBgPattern.svg");
}

#map-holder.dark-mode {
  background-image: url("darkBgPattern.svg");
}

.table {
  cursor: pointer;
  transition: all 0.2s;
}

#map-holder.dark-mode .table {
  fill: #373b53;
}

.table-on {
  fill: #424588 !important;
}

#map-holder.dark-mode .symbols {
  fill: #9498F3 !important;
}

#map-holder.dark-mode .table-on {
  fill: #9498f3 !important;
  stroke: #fff;
  stroke-width: 2;
}